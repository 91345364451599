import {Scope} from 'config';
import {useDeviceVar} from 'hooks/useDeviceVars';
import {useMobWebAdInterlayer} from 'hooks/useMobWebAdInterlayer';
import {useRouter} from 'hooks/useRouter';
import {useScope} from 'hooks/useScope';
import {useUserAgent} from 'hooks/useUserAgent';
import {useCallback} from 'react';
import {type QueryFilter, type QueryFilterRule, MobWebAdInterlayerMode} from 'types/deviceVars';
import {PageSource} from 'types/PageSource';
import {QueryMap} from 'utils/url';
import {getPlatformUrl} from 'utils/url/app';

import type {Props} from './Banner';

const MOBWEB_INTERLAYER_MODES_TO_SHOW_BANNER: MobWebAdInterlayerMode[] = [
  'gentle',
  'hardcore',
  'superhardcore',
];
const MOBWEB_INTERLAYER_SOURCES_TO_HIDE_BANNER: Props['source'][] = [PageSource.AUTH];

function checkParam(rule: QueryFilterRule, value?: string) {
  const {exclude, include} = rule;

  if (include) {
    if (!value) {
      return false;
    }
    if (Array.isArray(include)) {
      return include.includes(value);
    }
    return include === value;
  }

  if (exclude) {
    if (!value) {
      return true;
    }
    if (Array.isArray(exclude)) {
      return !exclude.includes(value);
    }
    return exclude !== value;
  }

  return false;
}

const checkParams = (settings: QueryFilter[], queryObject: QueryMap<string>) => {
  return settings.some((setting) => {
    const allExludingSettings = Object.keys(setting).filter((key) => {
      return setting[key]!.exclude === '*';
    });

    if (allExludingSettings.length) {
      if (allExludingSettings.some((s) => queryObject[s])) {
        return false;
      }
      return true;
    }

    const allIncludingSettings = Object.keys(setting).filter((key) => {
      return setting[key]!.include === '*';
    });

    if (allIncludingSettings.length) {
      if (allIncludingSettings.some((s) => queryObject[s])) {
        return true;
      }
      return false;
    }

    const settingsItemsKeys = Object.keys(setting);

    return settingsItemsKeys.every((settingItemKey) => {
      const settingItemValue = setting[settingItemKey]!;

      const values = queryObject[settingItemKey];
      const value = Array.isArray(values) ? values[0] : values;
      return checkParam(settingItemValue, value);
    });
  });
};

const browserRestrictions = {
  safari: ['success', 'orders'] as Props['source'][],
} satisfies Record<string, Props['source'][]>;

export function hasProperParams(
  query: QueryMap<string>,
  source: Props['source'],
  settings: QueryFilter[] = [],
): boolean {
  // for other pages params logic is not required
  if (source !== 'main' && source !== 'product') {
    return true;
  }

  return checkParams(settings, query);
}

export function useJoomAppMobileBanner(source: Props['source']): (() => string) | null {
  const userAgent = useUserAgent();
  const enabled = useDeviceVar('webJoomAppMobileBannerEnabled');
  const settings = useDeviceVar('webJoomAppMobileBanner');

  const shouldShowForMobWebInterlayer =
    useMobWebAdInterlayer(MOBWEB_INTERLAYER_MODES_TO_SHOW_BANNER) &&
    !MOBWEB_INTERLAYER_SOURCES_TO_HIDE_BANNER.includes(source);

  const router = useRouter();
  const scope = useScope();
  const appLinkHref = getPlatformUrl(scope, userAgent);
  const browserName = userAgent.browser.name;
  const passesBrowserRestrictions =
    (browserName !== 'Safari' && browserName !== 'Mobile Safari') ||
    browserRestrictions.safari.includes(source);

  const appLinkGetter = useCallback(() => appLinkHref, [appLinkHref]);

  if (!enabled && !shouldShowForMobWebInterlayer) {
    return null;
  }

  if (
    scope.is(Scope.GLOBAL) &&
    (shouldShowForMobWebInterlayer ||
      (hasProperParams(router.location.query, source, settings) && appLinkHref)) &&
    passesBrowserRestrictions
  ) {
    return appLinkGetter as () => string;
  }

  return null;
}
