import {PAGE_PARAM} from 'components/ProductList/consts';
import {Location} from 'history';
import {createUrl} from 'utils/url';

export function getProductsUrl(location: Location, pageToken?: string): string {
  const {pathname, search} = location;

  return createUrl(`${pathname}${search}`, {
    [PAGE_PARAM]: pageToken || '',
  });
}
