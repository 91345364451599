import {ContentList} from 'components/ContentList';
import {DotLoader} from 'components/DotLoader';
import React from 'react';
import {useLocation} from 'react-router-dom';
import {ContentListItem} from 'types/ContentList';
import {PageSource} from 'types/PageSource';
import {ProductLite} from 'types/ProductLite';
import {rootLocator} from 'utils/rootLocator';

import styles from './index.scss';
import {getProductsUrl} from './utils';

const locator = rootLocator.mainPage;

type Props = {
  items?: Array<ContentListItem>;
  itemsLite?: boolean;
  itemsEmpty?: boolean;
  itemsError?: Record<string, unknown> | null;
  itemsLoading?: boolean;
  itemsNextPageToken?: string | null;
  itemsOutdated: boolean;
  loadItems?: () => Promise<unknown>;
  onFavorite?: (product: ProductLite) => Promise<unknown>;
  onUnfavorite?: (product: ProductLite) => Promise<unknown>;
};

export function Main(props: Props): JSX.Element | null {
  const {
    items,
    itemsLite = false,
    itemsEmpty = false,
    itemsError = null,
    itemsLoading = false,
    itemsNextPageToken = null,
    itemsOutdated,
    loadItems,
    onFavorite,
    onUnfavorite,
  } = props;
  const location = useLocation();

  if (itemsError) {
    return null;
  }

  if (itemsEmpty) {
    return (
      <div className={styles.loader}>
        <DotLoader />
      </div>
    );
  }

  const moreUrl =
    itemsNextPageToken && location ? getProductsUrl(location, itemsNextPageToken) : null;
  const outdatedUrl = itemsOutdated && location ? getProductsUrl(location) : null;

  return (
    <div className={styles.main} {...locator()}>
      <ContentList
        items={items}
        loading={itemsLoading}
        moreUrl={moreUrl}
        onMore={itemsNextPageToken ? loadItems : undefined}
        onFavorite={onFavorite}
        onUnfavorite={onUnfavorite}
        outdatedUrl={outdatedUrl}
        source={PageSource.BEST}
        {...locator.contentList()}
      />
      {itemsLite && (
        <div className={styles.liteLoader}>
          <DotLoader />
        </div>
      )}
    </div>
  );
}
