import {useApiClient} from 'hooks/useApiClient';
import {useDeviceVar} from 'hooks/useDeviceVars';
import {useObserveOnce} from 'hooks/useObserveOnce';
import React, {useCallback, useRef} from 'react';

type ReturnType = {onClick: () => void};

function useViews({timeoutHours}: {timeoutHours: number}) {
  const STORAGE_KEY = 'joomAppMobileBannerViews';
  const client = useApiClient();
  const storage = client.storages.local;

  const timeoutMs = timeoutHours * 60 * 60 * 1000;

  const addView = useCallback(
    (viewTime: number) => {
      const record = storage.getItem(STORAGE_KEY) as string;
      let value;
      try {
        value = JSON.parse(record);
      } catch {
        value = [];
      }
      const nextValue = [...value, viewTime];
      storage.setItem(STORAGE_KEY, JSON.stringify(nextValue));
    },
    [storage],
  );

  const discardViews = useCallback(() => {
    storage.removeItem(STORAGE_KEY);
  }, [storage]);

  const clearExpiredViews = useCallback(() => {
    const record = storage.getItem(STORAGE_KEY) as string;
    let value;
    try {
      value = JSON.parse(record);
      if (!Array.isArray(value)) {
        return;
      }
      const nextValue = value.filter((item) => {
        const itemTimestamp = new Date(item).getTime();
        return itemTimestamp + timeoutMs > Date.now();
      });
      storage.setItem(STORAGE_KEY, JSON.stringify(nextValue));
      // eslint-disable-next-line no-empty
    } catch {}
  }, [storage, timeoutMs]);

  const getViewsAmount = useCallback<() => number>(() => {
    const record = storage.getItem(STORAGE_KEY) as string;
    let value;
    try {
      value = JSON.parse(record);
    } catch {
      return 0;
    }

    return value.length;
  }, [storage]);

  const viewsExist = useCallback(() => {
    return Boolean(storage.getItem(STORAGE_KEY));
  }, [storage]);

  return {addView, discardViews, getViewsAmount, clearExpiredViews, viewsExist};
}

export function useHideByViews(ref: React.RefObject<HTMLDivElement>): ReturnType {
  const client = useApiClient();
  const cookie = client.cookiesRegistry.appButtonHidden;

  const {
    enabled: hideAfterViewsEnabled = false,
    timeoutHours = 24,
    amountOfViews = 10,
  } = useDeviceVar('hideAppButtonByJoomAppBannerViews') || {};

  const {addView, discardViews, viewsExist, getViewsAmount, clearExpiredViews} = useViews({
    timeoutHours,
  });

  const setCookie = useCallback(() => {
    cookie.store(true);
  }, [cookie]);

  const onClick = useCallback(() => {
    if (!hideAfterViewsEnabled) {
      return;
    }

    setCookie();
    discardViews();
  }, [hideAfterViewsEnabled, setCookie, discardViews]);

  const handleBannerObserve = useCallback(() => {
    if (!hideAfterViewsEnabled) {
      return;
    }

    const cookieValue = cookie.restore();

    if (cookieValue) {
      return;
    }

    if (!viewsExist()) {
      addView(Date.now());
      return;
    }

    addView(Date.now());
    clearExpiredViews();
    const currentViewsNum = getViewsAmount();
    if (currentViewsNum > amountOfViews) {
      setCookie();
      discardViews();
    }
  }, [
    hideAfterViewsEnabled,
    cookie,
    viewsExist,
    clearExpiredViews,
    addView,
    getViewsAmount,
    amountOfViews,
    setCookie,
    discardViews,
  ]);

  useObserveOnce(handleBannerObserve, {
    targetRef: ref,
  });

  return {onClick};
}

export function useHiddenAfterViews(): boolean {
  const {enabled = false} = useDeviceVar('hideAppButtonByJoomAppBannerViews') || {};
  const client = useApiClient();
  const hideAppButtonCookieExists = useRef(
    Boolean(client.cookiesRegistry.appButtonHidden.exists()),
  );

  return enabled && hideAppButtonCookieExists.current;
}
