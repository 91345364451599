import {asyncConnect} from 'helpers/asyncConnect';
import {ComponentType} from 'react';
import {connect as reactReduxConnect, ConnectedComponent, Matching} from 'react-redux';
import {ConnectOptions} from 'react-redux/es/components/connect';
import {RootState} from 'store/rootReducer';

import {Binding} from './types';

export function bindingConnect<
  TProps extends Record<string, unknown>,
  TStateProps extends Record<string, unknown>,
  TDispatchProps extends Record<string, unknown>,
  TMergeProps extends Record<string, unknown>,
>(
  binding: Binding<TProps, TStateProps, TDispatchProps, TMergeProps>,
  options?: ConnectOptions<RootState, TStateProps, TProps, TMergeProps>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (Page: ComponentType<any>) => ComponentType<Matching<TMergeProps, TProps>> {
  const connector = reactReduxConnect<TStateProps, TDispatchProps, TProps, TMergeProps, RootState>(
    binding.mapStateToProps,
    binding.mapDispatchToProps,
    binding.mergeProps,
    options,
  );

  const asyncConnector = asyncConnect(binding.async);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (Page: ComponentType<any>): ComponentType<Matching<TMergeProps, TProps>> => {
    const Connected = connector(Page) as ConnectedComponent<ComponentType, unknown>;
    return asyncConnector(Connected) as ComponentType<Matching<TMergeProps, TProps>>;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BindingProps<T extends {mergeProps: any}> = ReturnType<T['mergeProps']>;
