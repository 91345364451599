function mergeFunctions(arr, key, args) {
  return arr
    .map((item) => item[key] && item[key](...args))
    .filter((item) => !!item)
    .reduce((next, result) => {
      if (result) {
        Object.keys(result).forEach((param) => {
          next[param] = result[param];
        });
      }
      return next;
    }, {});
}

function mergeArrays(arr, key) {
  const results = arr.map((item) => item[key]).filter((item) => !!item);
  return Array.prototype.concat(...results);
}

export function mergeBindings(bindings) {
  return {
    mapStateToProps: (...args) => mergeFunctions(bindings, 'mapStateToProps', args),
    mapDispatchToProps: (...args) => mergeFunctions(bindings, 'mapDispatchToProps', args),
    mergeProps: (stateProps, dispatchProps, ownProps) => ({
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
      ...mergeFunctions(bindings, 'afterMergeProps', [stateProps, dispatchProps, ownProps]),
    }),
    async: mergeArrays(bindings, 'async'),
  };
}

export function createLoader(isLoaded, load, select = null, error = null) {
  function loader(dispatch, getState, ...args) {
    const promise = isLoaded(getState(), ...args) ? Promise.resolve(null) : dispatch(load(...args));

    return promise
      .then(() => (select ? select(getState(), ...args) : null))
      .catch(() => (error ? error(getState(), ...args) : null));
  }

  loader.load = load;
  loader.isLoaded = isLoaded;
  loader.select = select;
  loader.error = error;

  return loader;
}

export function connectLoader(store, loader) {
  const wrap =
    (fn, passFunctions) =>
    (...args) => {
      if (!fn) {
        return null;
      }
      if (passFunctions) {
        return fn(store.dispatch, store.getState, ...args);
      }
      return fn(store.getState(), ...args);
    };

  const connectedLoader = wrap(loader, true);
  connectedLoader.load = wrap(loader.load, true);
  connectedLoader.isLoaded = wrap(loader.isLoaded);
  connectedLoader.select = wrap(loader.select);
  connectedLoader.error = wrap(loader.error);

  return connectedLoader;
}

// for backwards compatibility
export default mergeBindings;
