import {useAppLinkHandler} from 'hooks/useAppLink';
import {usePreviewEvent} from 'hooks/usePreviewEvent';
import React, {useRef} from 'react';
import {PageSource} from 'types/PageSource';

import {JoomAppMobileBannerUi} from './BannerUi';
import styles from './index.scss';
import {useHideByViews} from './useHideByViews';
import {useJoomAppMobileBanner} from './useJoomAppMobileBanner';

export type Props = {
  onClose?: () => void;
  showActionButton?: boolean;
  bgColor?: 'gray' | 'white';
  source: PageSource;
  deepUrl?: string;
};

export const JoomAppMobileBanner: React.FC<Props> = ({
  onClose,
  showActionButton = true,
  bgColor = 'gray',
  source,
  deepUrl = '',
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const appLinkHref = useJoomAppMobileBanner(source);
  const {onClick} = useHideByViews(ref);
  const handleClick = useAppLinkHandler({target: deepUrl, onClick});

  usePreviewEvent(
    {
      rootRef: ref,
      previewEvent: {
        type: 'previewToInstall',
        payload: {
          source,
        },
      },
    },
    {
      clickEvent: {
        type: 'goToInstall',
        payload: {
          source,
        },
      },
    },
  );

  if (!appLinkHref) {
    return null;
  }

  return (
    <div ref={ref} className={styles.wrapper}>
      <JoomAppMobileBannerUi
        onClose={onClose}
        showActionButton={showActionButton}
        bgColor={bgColor}
        appLinkHref={appLinkHref}
        onClick={handleClick}
      />
    </div>
  );
};
