import {bindActionCreators} from 'redux';
import {isBot} from 'store/modules/preferences';
import {
  getBest,
  getBestError,
  getBestNextPageToken,
  isBestEmpty,
  isBestLoaded,
  isBestLite,
  isBestLoading,
  isBestOutdated,
  loadBest,
  loadBestLite,
} from 'store/modules/best';
import {PAGE_PARAM} from 'components/ProductList/consts';
import {START_PAGE_TOKEN} from 'store/consts';
import {getQueryData} from 'utils/url';
import {RenderingType} from 'types/Rendering';

const BOT_PAGE_SIZE = 500;
const MAIN_TAB_TYPE = 'productGroup';

export default {
  mapStateToProps: (state) => ({
    tabType: MAIN_TAB_TYPE, // Need this for analytics
    items: getBest(state),
    itemsLite: isBestLite(state),
    itemsEmpty: isBestEmpty(state),
    itemsError: getBestError(state),
    itemsLoading: isBestLoading(state),
    itemsNextPageToken: getBestNextPageToken(state),
    itemsOutdated: isBestOutdated(state),
  }),
  mapDispatchToProps: (dispatch) =>
    bindActionCreators(
      {
        loadBest,
      },
      dispatch,
    ),
  afterMergeProps: ({itemsNextPageToken}, dispatchProps) => ({
    itemsLoadMore: () => dispatchProps.loadBest(itemsNextPageToken),
  }),
  async: [
    {
      ssr: (client) => client.device.getDeviceVar('webMainLite'),
      csr: (client) => client.device.getDeviceVar('webMainLite'),
      promise: ({store: {dispatch, getState}, location, history, client, initialAfterSsr}) => {
        const query = getQueryData(location.search);
        if (isBot(getState()) || query[PAGE_PARAM]) {
          return undefined;
        }

        const forceLoad = history?.action !== 'POP' && !initialAfterSsr;

        if (forceLoad || !isBestLoaded(getState(), START_PAGE_TOKEN)) {
          return dispatch(loadBestLite());
        }

        return undefined;
      },
    },
    {
      delayed: (client) => client.device.getDeviceVar('webMainDelayed'),
      deferred: (client) => !client.device.getDeviceVar('webMainDelayed'),
      promise: ({store: {dispatch, getState}, location, history, client, initialAfterSsr}) => {
        if (client.device.getRenderingConfig()?.option === RenderingType.META) {
          return undefined;
        }

        const query = getQueryData(location.search);
        const pageToken = query[PAGE_PARAM] || null;
        const forceLoad = history?.action !== 'POP' && !initialAfterSsr;

        if (
          forceLoad ||
          isBestLite(getState()) ||
          !isBestLoaded(getState(), pageToken || START_PAGE_TOKEN)
        ) {
          return dispatch(
            loadBest(pageToken, isBot(getState()) && pageToken ? BOT_PAGE_SIZE : undefined),
          );
        }

        return undefined;
      },
    },
  ],
};
