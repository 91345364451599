import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages} from 'react-intl';
import {bindingConnect} from 'utils/bindings/connect';
import {Main} from 'components/NewMain';
import {ContentListItemShape} from 'shapes/ContentListItem';
import AnalyticsShape from 'shapes/Analytics';
import ErrorShape from 'shapes/Error';
import {PageBase} from 'containers/PageBase';
import {EmailPromoCorner} from 'containers/WelcomePopups/EmailPromoCorner';
import {OpenAppPopup} from 'containers/WelcomePopups/OpenAppPopup';
import {RewardWheelCTAPopup} from 'containers/WelcomePopups/RewardWheelCTAPopup';
import {JoomAppMobileBanner} from 'components/JoomAppMobileBanner';
import {PageSource} from 'types/PageSource';
import {getUrl} from 'routes';
import {getByScope} from 'helpers/ApiClient/Scope/utils';
import {Scope} from 'config';
import bindings from './bindings';
import {DESCRIPTION_BY_LANG, TITLE_BY_LANG} from './constants';

const messages = defineMessages({
  description: {
    defaultMessage:
      "Products at low prices that your city's stores cannot offer, and free shipping!",
    description: 'Description for home page',
  },
  geekDescription: {
    defaultMessage:
      'The first online store dedicated to technical gadgets and new products for geeks. An extensive collection of high-quality products with free shipping and warranty.',
    description: 'Description for home page [joom geek]',
  },
  coolbeDescription: {
    defaultMessage:
      'An online store with an extensive selection of high quality products and warranty',
    description: 'Description for home page [coolbe]',
  },
  title: {
    defaultMessage: '{companyName}. Shop every day, or whenever you feel like it',
    description: `
      Html title for home page.
      English translation has to be the following: "Joom. Great prices. Free shipping. Easy to use".
      For other languages use the english or the russian text`,
  },
  geekTitle: {
    defaultMessage: 'Joom. Great prices. Free shipping. Easy to use',
    description: `
      Html title for home page.
      English translation has to be the following: "Joom. Great prices. Free shipping. Easy to use".
      For other languages use the english or the russian text`,
  },
});

class MainPage extends PageBase {
  static propTypes = {
    analytics: AnalyticsShape.isRequired,
    items: PropTypes.arrayOf(ContentListItemShape),
    itemsLite: PropTypes.bool.isRequired,
    itemsEmpty: PropTypes.bool.isRequired,
    itemsError: ErrorShape,
    itemsLoading: PropTypes.bool.isRequired,
    itemsLoadMore: PropTypes.func.isRequired,
    itemsNextPageToken: PropTypes.string,
    itemsOutdated: PropTypes.bool.isRequired,
    joomMobileAppBannerClosed: PropTypes.bool.isRequired,
    closeJoomMobileAppBanner: PropTypes.func.isRequired,
  };

  static defaultProps = {
    items: null,
    itemsError: null,
    itemsNextPageToken: null,
  };

  mountTime = 0;

  whiteBackgroundSupported = true;

  componentDidMount(...args) {
    super.componentDidMount(...args);
    this.mountTime = Date.now();
    this.props.client.riskified.go('landing');
    this.props.analytics.sendEvent({type: 'homeOpen'});
    this.props.analytics.dataLayer({
      event: 'Main. Page View',
    });
    global.addEventListener('beforeunload', this.handleLeavePage, false);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    global.removeEventListener('beforeunload', this.handleLeavePage, false);
    this.handleLeavePage();
  }

  handleLeavePage = () => {
    this.props.analytics.sendEvent(
      {
        type: 'homeClose',
        payload: {
          sinceOpenMs: Date.now() - this.mountTime,
        },
      },
      {
        beacon: true,
        immediately: true,
      },
    );
  };

  getDeepUrl() {
    return 'home';
  }

  hasOwnCategories() {
    return true;
  }

  hasMobileStickyHeader() {
    return true;
  }

  hasHeaderCoupon() {
    return true;
  }

  hasSnow() {
    return true;
  }

  getLangPath(lang) {
    return getUrl('main', {lang, scope: this.props.scope});
  }

  getPageTitle() {
    const {intl, language, scope} = this.props;

    if (scope.is(Scope.GLOBAL) && language in TITLE_BY_LANG) {
      return TITLE_BY_LANG[language];
    }

    const {companyName} = scope.companyInfo;

    return intl.formatMessage(
      getByScope(scope, {
        [Scope.GLOBAL]: messages.title,
        [Scope.GEEK]: messages.geekTitle,
      }),
      {companyName},
    );
  }

  getPageDescription() {
    const {intl, language, scope} = this.props;

    if (scope.is(Scope.GLOBAL) && language in DESCRIPTION_BY_LANG) {
      return DESCRIPTION_BY_LANG[language];
    }

    return intl.formatMessage(
      getByScope(scope, {
        [Scope.GLOBAL]: messages.description,
        [Scope.GEEK]: messages.geekDescription,
        [Scope.COOLBE]: messages.coolbeDescription,
        [Scope.CBTREND]: messages.coolbeDescription,
      }),
    );
  }

  getHelmetProps(location = this.props.location) {
    return {
      ...super.getHelmetProps(location),
      titleTemplate: '%s',
    };
  }

  getPageSource() {
    return PageSource.BEST;
  }

  renderTopHeaderAppBanner() {
    const {joomMobileAppBannerClosed, closeJoomMobileAppBanner} = this.props;

    if (joomMobileAppBannerClosed) {
      return null;
    }

    return (
      <div>
        <JoomAppMobileBanner
          bgColor="gray"
          onClose={closeJoomMobileAppBanner}
          source={PageSource.MAIN}
          deepUrl={this.getDeepUrl()}
        />
      </div>
    );
  }

  renderContent() {
    const {
      items,
      itemsLite,
      itemsEmpty,
      itemsError,
      itemsLoading,
      itemsLoadMore,
      itemsNextPageToken,
      itemsOutdated,
      location,
    } = this.props;

    if (itemsError) {
      return this.createPageError(itemsError, {noButton: true});
    }

    return (
      <>
        <Main
          items={items}
          itemsLite={itemsLite}
          itemsEmpty={itemsEmpty}
          itemsError={itemsError}
          itemsLoading={itemsLoading}
          itemsNextPageToken={itemsNextPageToken}
          itemsOutdated={itemsOutdated}
          loadItems={itemsLoadMore}
          location={location}
          onFavorite={this.handleProductAddToCollection}
          onUnfavorite={this.handleProductRemoveFromCollection}
        />
        <OpenAppPopup />
        <EmailPromoCorner />
        <RewardWheelCTAPopup />
      </>
    );
  }
}

export default bindingConnect(bindings)(MainPage);
