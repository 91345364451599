import classnames from 'classnames/bind';
import {AppLink} from 'components/AppLink';
import {Button} from 'components/UIKit/Button';
import Chevron from 'components/UIKit/Icon/img/mono/x1/chevron-right-linear-16.jsx.svg';
import Close from 'components/UIKit/Icon/img/mono/x1/close-linear-16.jsx.svg';
import Icon from 'components/UIKit/Icon/img/multi/x1/joom-64.jsx.svg';
import {useCompanyName} from 'hooks/useCompanyName';
import {useScope} from 'hooks/useScope';
import React, {SyntheticEvent} from 'react';
import {FormattedMessage} from 'react-intl';
import {QueryMap} from 'utils/url';

import type {Props} from './Banner';
import styles from './index.scss';
import JmtIcon from './jmtIcon.jsx.svg';

const cn = classnames.bind(styles);

type UiProps = Omit<Props, 'source' | 'deepUrl'> & {
  appLinkHref: (target?: string, params?: QueryMap) => string;
  onClick?: (evt: SyntheticEvent) => void;
};

export const JoomAppMobileBannerUi: React.FC<UiProps> = ({
  onClose,
  showActionButton = true,
  bgColor = 'gray',
  appLinkHref,
  onClick,
}: UiProps) => {
  const companyName = useCompanyName();
  const scope = useScope();

  return (
    <div className={cn('banner', {[bgColor]: Boolean(bgColor)})}>
      {onClose ? (
        <button type="button" onClick={onClose} className={styles.close}>
          <Close />
        </button>
      ) : null}
      <AppLink className={styles.appLink} href={appLinkHref()} role="button" onClick={onClick}>
        <div className={styles.iconWrapper}>
          {scope.isJmt ? <JmtIcon className={styles.jmtIcon} /> : <Icon className={styles.icon} />}
        </div>
        <div className={styles.text}>
          <div className={styles.title}>
            <FormattedMessage
              description="Title text in Joom app banner"
              defaultMessage="More bargains"
            />
          </div>
          <div className={styles.subtitle}>
            <FormattedMessage
              description="Subtitle text in Joom app banner"
              defaultMessage="In the {companyName} app"
              values={{companyName}}
            />
          </div>
        </div>
        {showActionButton ? (
          <div className={styles.button}>
            <Button tag="button" size="small" color="primary">
              <FormattedMessage
                description="Текст кнопки Скачать в баннере приложения Joom"
                defaultMessage="Download"
              />
            </Button>
          </div>
        ) : (
          <Chevron className={styles.chevron} />
        )}
      </AppLink>
    </div>
  );
};
