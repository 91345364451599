export const TITLE_DE =
  'Joom Deutschland: Kaufe hochwertige Produkte online zu günstigen Preisen mit Gratis-Lieferung';
export const DESCRIPTION_DE =
  'Große Auswahl an Kleidung, Accessoires, Elektronik, Haushaltswaren, Spielzeug, Geschenken und mehr! Sonderangebote und Rabatte für Neukunden. Kostenlose und schnelle Lieferung deutschland- und europaweit.';
export const TITLE_RO =
  'Cumpără online produse de calitate din China la prețuri accesibile și cu transport gratuit | Joom Romania';
export const DESCRIPTION_RO =
  'Comandă produse ieftine online cu livrare gratuită și rapidă în toată România și Europa! O gamă largă de îmbrăcăminte, accesorii, electronice, articole pentru casă, jucării, cadouri și multe altele! Promoții și reduceri pentru noii utilizatori.';
export const TITLE_PL =
  'Kupuj wysokiej jakości artykuły z Chin online w przystępnych cenach z bezpłatną dostawą | Joom Poland';
export const DESCRIPTION_PL =
  'Duży wybór odzieży, akcesoriów, elektroniki, artykułów gospodarstwa domowego, zabawek, prezentów i nie tylko! Promocje i zniżki dla nowych użytkowników. Bezpłatna i szybka dostawa na terenie Niemiec i całej Europy.';
export const TITLE_ES =
  'Joom España: compra productos de calidad online a precios asequibles con entrega gratuita';
export const DESCRIPTION_ES =
  '¡Pide productos baratos online con entrega gratuita y rápida en toda España y toda Europa! ¡Gran selección de ropa, accesorios, electrónica, productos para el hogar, juguetes, regalos y mucho más! Promociones y descuentos para nuevos usuarios.';

export const TITLE_BY_LANG = {
  de: TITLE_DE,
  ro: TITLE_RO,
  pl: TITLE_PL,
  es: TITLE_ES,
};
export const DESCRIPTION_BY_LANG = {
  de: DESCRIPTION_DE,
  ro: DESCRIPTION_RO,
  pl: DESCRIPTION_PL,
  es: DESCRIPTION_ES,
};
